import { AsyncPipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
    selector: 'nts-model-box',
    templateUrl: './model-box.component.html',
    styleUrls: ['./model-box.component.scss'],
    standalone: true,
    imports: [
        SvgIconComponent,
        AsyncPipe
    ]
})
export class ModelBoxComponent implements OnInit {

    @Input() model!: { identity: number, description: string };
    @Input() selected = false;
    @Input() isDisable = false;
    @Input() icon = '';
    @Output() onClicked = new EventEmitter<{ identity: number, description: string }>();

    constructor() { }

    ngOnInit(): void {
    }

    onClick(model: { identity: number, description: string }) {
        if (!this.isDisable) {
            this.onClicked.emit(model)
        }
    }
}
