import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, ChildrenOutletContexts, RouterOutlet } from '@angular/router';
import { OnlineService, StatusMessageInterface, UpdateService } from '@nts/std/src/lib/utility';
import { CurrentRouteService, EmptyContainerComponent, RoutingService, ToastMessageService, TokenService } from '@nts/std';
import { AuthService } from '@nts/std';
import { FrameworkServiceApiClient } from '@nts/std';
import { fadeInAnimation } from '../../animations/fade-in.animation';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { TelemetryService } from '@nts/std/src/lib/telemetry';
import { StepContainerComponentInterface } from './step-container-component.interface';

@UntilDestroy()
@Component({
  selector: 'nts-empty-container-with-animation',
  templateUrl: './empty-container-with-animation.component.html',
  styleUrls: ['./empty-container-with-animation.component.scss'],
  animations: [
    fadeInAnimation
  ],
  standalone: true,
  imports: [
    RouterOutlet

  ]
})
export class EmptyContainerWithAnimationComponent extends EmptyContainerComponent {

  protected override outletComponent: StepContainerComponentInterface | null = null;

  constructor(
    protected override readonly routingService: RoutingService,
    protected override readonly activatedRoute: ActivatedRoute,
    protected override readonly tokenService: TokenService,
    protected override readonly toastMessageService: ToastMessageService,
    protected override readonly updateService: UpdateService,
    protected override readonly onlineService: OnlineService,
    private contexts: ChildrenOutletContexts,
    private auth: AuthService,
    private frameworkApi: FrameworkServiceApiClient,
    protected override readonly telemetryService: TelemetryService,
    protected override readonly environmentConfiguration: EnvironmentConfiguration,
    protected override readonly cd: ChangeDetectorRef,
    protected override readonly componentFactoryResolver: ComponentFactoryResolver,
    protected override readonly currentRouteService: CurrentRouteService,
  ) {
    super(
      routingService,
      activatedRoute,
      tokenService,
      toastMessageService,
      updateService,
      onlineService,
      auth,
      telemetryService,
      environmentConfiguration,
      cd,
      componentFactoryResolver,
      currentRouteService
    )
  }


  override ngOnInit(): void {
    super.ngOnInit();

    // Se non è stato inizializzato correttamente l'enterprise data
    // Provo a ricaricare le informazioni
    // this.auth.getTenantId().then(async (tenantId: number) => {
    //   if (await this.auth.getEnterpriseData(tenantId) == null) {
    //     await this.auth.setAuthDataFromQueryString(window.location.search);
    //     let enterprisesList = null;
    //     const enterprisesListResponse: GenericServiceResponse<EnterprisesListDto> = await firstValueFrom(
    //       this.frameworkApi.getEnterprisesList()
    //     );
    //     if (enterprisesListResponse.operationSuccedeed) {
    //       enterprisesList = enterprisesListResponse.result;
    //     }
    //     await this.auth.initEnterpriseData(enterprisesList);
    //   }
    // })
  }

  override async checkStatus(): Promise<StatusMessageInterface> {
    if (this.outletComponent.checkStatus) {
      return this.outletComponent.checkStatus();
    }
    return {
      hasModalOpen: false,
      pendingChanges: false,
      version: 1
    }
  }


  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
