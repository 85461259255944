
import { ModuleWithProviders, NgModule } from "@angular/core";
import { sharedIcons } from "../svg/shared";
import { CustomMailChipsComponent } from "./components/custom-mail-chips/custom-mail-chips.component";
import { provideSvgIcons } from '@ngneat/svg-icon';
import { EmptyContainerWithAnimationComponent } from "./components/empty-container-with-animation/empty-container-with-animation.component";
import { ModelBoxComponent } from "./components/model-box/model-box.component";

export const EXTRACT_INTRA_DATA_LONG_OP_SLUG = `extractintradatalongop`;
export const EXTRACT_INTRA_DATA_LONG_OP_FULL_PATH = `/manage/${EXTRACT_INTRA_DATA_LONG_OP_SLUG}`;

export const SPOOL_SLUG = `operation`;
export const SPOOL_FULL_PATH = `/manage/${SPOOL_SLUG}`;
export const SPOOL_TEMPLATE_FULL_NAME = 'SpoolService.OperationObjects.Models.Operation';

export const SHOW_INTRA_DATA_LONG_OP_SLUG = `showintradatalongop`;
export const SHOW_INTRA_DATA_LONG_OP_FULL_PATH = `/manage/${SHOW_INTRA_DATA_LONG_OP_SLUG}`;

export const EXTRACT_SUMMARY_LIST_LONG_OP_SLUG = `extractsummarylistlongop`;
export const EXTRACT_SUMMARY_LIST_LONG_OP_FULL_PATH = `/manage/${EXTRACT_SUMMARY_LIST_LONG_OP_SLUG}`;

export const SHOW_SUMMARY_LIST_LONG_OP_SLUG = `showsummarylistlongop`;
export const SHOW_SUMMARY_LIST_LONG_OP_FULL_PATH = `/manage/${SHOW_SUMMARY_LIST_LONG_OP_SLUG}`;

export const SHOW_SUMMARY_LIST_EXTRACTION_DATA_LONG_OP_SLUG = `showsummarylistextractiondatalongop`;
export const SHOW_SUMMARY_LIST_EXTRACTION_DATA_LONG_OP_FULL_PATH = `/manage/${SHOW_SUMMARY_LIST_EXTRACTION_DATA_LONG_OP_SLUG}`;

export const SHOW_DELETED_DEFINITIVE_SUMMARY_LIST_DATA_LONG_OP_SLUG = `showdeleteddefinitivesummarylistdatalongop`;
export const SHOW_DELETED_DEFINITIVE_SUMMARY_LIST_DATA_LONG_OP_FULL_PATH = `/manage/${SHOW_DELETED_DEFINITIVE_SUMMARY_LIST_DATA_LONG_OP_SLUG}`;

export const SUBJECT_SUPPLIER_FULL_NAME = `Subject.SupplierObjects.Models.Supplier`;
export const SUBJECT_CUSTOMER_FULL_NAME = `Subject.CustomerObjects.Models.Customer`;

@NgModule({
    imports: [
        CustomMailChipsComponent,
        EmptyContainerWithAnimationComponent,
        ModelBoxComponent,
    ],
    exports: [
        CustomMailChipsComponent,
        EmptyContainerWithAnimationComponent,
        ModelBoxComponent,
    ],
    providers: [provideSvgIcons(sharedIcons)]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                // RouteStateService,
            ]
        };
    }

    static forChild(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: []
        };
    }
}
