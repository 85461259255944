<div #labelledContainer class="main-container" [popper]="tooltipErrTemplate"
    [popperDisabled]="!propertyViewModel.hasErrors || propertyViewModel?.securityAccess != null"
    [popperTrigger]="ngxPopperjsTriggers.hover" popperApplyClass="error" [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false" [popperHideOnScroll]="true" popperAppendTo="body">
    <div class="label-container">
        <nts-label-box *ngIf="showLabel" [title]="propertyViewModel.metadataDescription"
            [label]="propertyViewModel.metadataShortDescription"></nts-label-box>
    </div>
    <div class="edit-container">
        <p-chips #customChips [class.has-error]="propertyViewModel.hasErrors" [(ngModel)]="arrayModel" [addOnTab]="true"
            [addOnBlur]="true" separator="," class="chips" (onAdd)="updatePropertyViewModel()"
            (onRemove)="updatePropertyViewModel()" [disabled]="!propertyViewModel.isEnabled">
        </p-chips>
    </div>

    <popper-content #tooltipErrTemplate>
        <ng-container *ngFor="let item of propertyViewModel.getErrors()">
            {{ item }}
        </ng-container>
    </popper-content>
</div>
