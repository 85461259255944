import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { BaseRoutes, MainContainerComponent, EmptyContainerComponent } from '@nts/std';
import { environment } from 'src/environments/environment';
import { EXTRACT_INTRA_DATA_LONG_OP_SLUG, EXTRACT_SUMMARY_LIST_LONG_OP_SLUG, SHOW_DELETED_DEFINITIVE_SUMMARY_LIST_DATA_LONG_OP_SLUG, SHOW_INTRA_DATA_LONG_OP_SLUG, SHOW_SUMMARY_LIST_EXTRACTION_DATA_LONG_OP_SLUG, SHOW_SUMMARY_LIST_LONG_OP_SLUG } from './shared/shared.module';

const title = environment ? environment.appTitle : 'Application Title';
const routes: Routes = [

  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getEntityRoutes(
        title,
        EXTRACT_INTRA_DATA_LONG_OP_SLUG,
        () => import('./extract-intra-data-long-op/extract-intra-data-long-op.module').then(m => m.ExtraIntraDataLongOpModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "yearlysettings",
        () => import('./yearly-settings/yearly-settings.module').then(m => m.YearlySettingsModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "intratenantsettings",
        () => import('./intra-tenant-settings/intra-tenant-settings.module').then(m => m.IntraTenantSettingsModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "extractintradataconfig",
        () => import('./extract-intra-data-config/extract-intra-data-config.module').then(m => m.ExtractIntraDataConfigModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "callofstockintradata",
        () => import('./intra-data/call-of-stock-intra-data/call-of-stock-intra-data.module').then(m => m.CallOfStockIntraDataModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "goodspurchaseintradata",
        () => import('./intra-data/goods-purchase-intra-data/goods-purchase-intra-data.module').then(m => m.GoodsPurchaseIntraDataModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "goodssaleintradata",
        () => import('./intra-data/goods-sale-intra-data/goods-sale-intra-data.module').then(m => m.GoodsSaleIntraDataModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "receivedserviceintradata",
        () => import('./intra-data/received-service-intra-data/received-service-intra-data.module').then(m => m.ReceivedServiceIntraDataModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "renderedserviceintradata",
        () => import('./intra-data/rendered-service-intra-data/rendered-service-intra-data.module').then(m => m.RenderedServiceIntraDataModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        SHOW_INTRA_DATA_LONG_OP_SLUG,
        () => import('./show-intra-data-long-op/show-intra-data-long-op.module').then(m => m.ShowIntraDataLongOpModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "intradatasummary",
        () => import('./intra-data-summary/intra-data-summary.module').then(m => m.IntraDataSummaryModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "extractsummarylistconfig",
        () => import('./extract-summary-list-config/extract-summary-list-config.module').then(m => m.ExtractSummaryListConfigModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "callofstocksummarylist",
        () => import('./summary-list/call-of-stock-summary-list/call-of-stock-summary-list.module').then(m => m.CallOfStockSummaryListModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "goodspurchaseamendsummarylist",
        () => import('./summary-list/goods-purchase-amend-summary-list/goods-purchase-amend-summary-list.module').then(m => m.GoodsPurchaseAmendSummaryListModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "goodspurchasesummarylist",
        () => import('./summary-list/goods-purchase-summary-list/goods-purchase-summary-list.module').then(m => m.GoodsPurchaseSummaryListModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "goodssaleamendsummarylist",
        () => import('./summary-list/goods-sale-amend-summary-list/goods-sale-amend-summary-list.module').then(m => m.GoodsSaleAmendSummaryListModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "goodssalesummarylist",
        () => import('./summary-list/goods-sale-summary-list/goods-sale-summary-list.module').then(m => m.GoodsSaleSummaryListModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "receivedserviceamendsummarylist",
        () => import('./summary-list/received-service-amend-summary-list/received-service-amend-summary-list.module').then(m => m.ReceivedServiceAmendSummaryListModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "receivedservicesummarylist",
        () => import('./summary-list/received-service-summary-list/received-service-summary-list.module').then(m => m.ReceivedServiceSummaryListModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "renderedserviceamendsummarylist",
        () => import('./summary-list/rendered-service-amend-summary-list/rendered-service-amend-summary-list.module').then(m => m.RenderedServiceAmendSummaryListModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "renderedservicesummarylist",
        () => import('./summary-list/rendered-service-summary-list/rendered-service-summary-list.module').then(m => m.RenderedServiceSummaryListModule)
      ),
      {
        path: 'manage/intradataaccounting',
        component: EmptyContainerComponent,
        loadChildren: () => import('./intra-data-accounting/intra-data-accounting.module').then(m => m.IntraDataAccountingModule),
      },
      ...BaseRoutes.getEntityRoutes(
        title,
        EXTRACT_SUMMARY_LIST_LONG_OP_SLUG,
        () => import('./extract-summary-list-long-op/extract-summary-list-long-op.module').then(m => m.ExtractSummaryListLongOpModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "summarylistextractiondata",
        () => import('./summary-list/summary-list-extraction-data/summary-list-extraction-data.module').then(m => m.SummaryListExtractionDataModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        SHOW_SUMMARY_LIST_LONG_OP_SLUG,
        () => import('./show-summary-list-long-op/show-summary-list-long-op.module').then(m => m.ShowSummaryListLongOpModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        SHOW_SUMMARY_LIST_EXTRACTION_DATA_LONG_OP_SLUG,
        () => import('./show-summary-list-extraction-data-long-op/show-summary-list-extraction-data-long-op.module').then(m => m.ShowSummaryListExtractionDataModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        SHOW_DELETED_DEFINITIVE_SUMMARY_LIST_DATA_LONG_OP_SLUG,
        () => import('./show-deleted-definitive-summary-list-data-long-op/show-deleted-definitive-summary-list-data-long-op.module').then(m => m.ShowDeletedDefinitiveSummaryListDataModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "intradatamanualentryconfig",
        () => import('./intra-data-manual-entry-config/intra-data-manual-entry-config.module').then(m => m.IntraDataManualEntryConfigModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      ),
    ]
  ),
  // #endregion rotte con autenticazione

  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()
];

@NgModule({
  imports: [
    // TODO Tommy: si potrebbe creare una custom preloadin strategy per precaricare solo le rotte offline
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', paramsInheritanceStrategy: 'always', preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  //
}
